import React from 'react'
import Ankle from './Ankle'
import BreadCrumbs from './BreadCrumbs'
import Footer from './Footer'
import SectionHeader from './SectionHeader'
import './SectionTemplate.scss'

const SectionTemplate = ({title, path, image, showBreadCrumbs = true, shortHeader = false, children}) => {
  const style = {
    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 66%, rgba(0,0,0,0.9) 100%), url(${image})`,
  };

  const headerClass = shortHeader ? "header short" : "header";
  return (
    <div className='SectionTemplate'>
      <SectionHeader>
      </SectionHeader>
      <div className='desktop-width-guard'>
        {path && <BreadCrumbs sectionTitle={title} path={path} />}
        <div className={headerClass} style={style}>
          <div className="headers" >
            <h1>{title}</h1>
          </div>
        </div>
        <div className='desktop-styling'>
          <div className="crib">
            {children}
          </div>
          <Ankle>
          </Ankle>
        </div>
      </div>

      <Footer>
      </Footer>
    </div>
  )
}

export default SectionTemplate