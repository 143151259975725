import { Link } from 'gatsby'
import React from 'react'
import './BreadCrumbs.scss'

const BreadCrumbs = ({sectionTitle, topicTitle, detailTitle, path}) => {
  const sectionPath = '/' + path.split('/')[0]
  const topicPath = sectionPath + '/' + path.split('/')[1]
  const detailPath = topicPath + '/' + path.split('/')[2]

  const fourthLevelPath = detailPath + '/' + path.split('/')[3]
  const fourthLevel = path.split('/')[3]
  var fourthLevelString = undefined
  if (fourthLevel !== undefined && /[a-z]/.test(fourthLevel) ) {
    fourthLevelString = fourthLevel.replace(/-/g, ' ')
  }

  const caret = () => <span> {'>'} </span>

  return (
    <div className="relative">
      <div className='BreadCrumbs'>
        <Link to={'/'}>Home</Link>
        {caret()}
        <Link to={sectionPath}>{sectionTitle}</Link>
        {topicTitle && caret()}
        {topicTitle && <Link to={topicPath}>{topicTitle}</Link>}
        {detailTitle && caret()}
        {detailTitle && <Link to={detailPath}>{detailTitle}</Link>}
        {fourthLevelString && caret()}
        {fourthLevelString && <Link to={fourthLevelPath}>{fourthLevelString}</Link>}
      </div>
    </div>
  )
}

export default BreadCrumbs