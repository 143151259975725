import React from "react"
import Search from '../components/Search'
import SectionTemplate from '../components/SectionTemplate'
import './example.scss'


export default function Example({data}) {
  return (
    <div className="Example">
      <SectionTemplate title="SEARCH">
        <Search />
      </SectionTemplate>
    </div>
  )
}

